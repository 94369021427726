<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <b-row>
          <b-col lg="6">
            <h3>รายการยกเลิกโพย</h3>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(CloseDate)="data">
        {{ time(data.item.CloseDate) }}
      </template>
      <template #cell(created_at)="data">
        {{ time(data.item.created_at) }}
      </template>
      <template #cell(Action)="data">
        <span @click="editData(data.item)">
          <b-avatar
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            v-b-tooltip.hover.v-warning.bottom
            variant="warning"
            title="แก้ไข"
          >
            <feather-icon icon="EditIcon" />
          </b-avatar>
        </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
          @change="getData()"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @input="getData()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// import vSelect from 'vue-select'
import moment from "moment-timezone";
import {
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBToggle,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      modalShow: false,
      optionsMember: "",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        { key: "index", label: "no." },
        { key: "LottoHeadName", label: "ประเภทหวย" },
        { key: "CloseDate", label: "งวดวันที่" },
        { key: "created_at", label: "วันที่ยกเลิก" },
        { key: "cancel_by", label: "ยกเลิกโดย" },
        { key: "Action", label: "Action" },
      ],
      items: [],
      Data: {
        id: null,
        name: null,
        prefix_name: null,
        manu_show: null,
      },
      imagePreview: null,
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    editData(item) {
      if (item) {
        this.$router.push({ name: 'cancel-detail', params: { id: item.round_id } })
      }
    },
    time(value) {
      return moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss");
    },
    getData() {
      const params = {
        perPage: this.perPage,
        Page: this.currentPage,
      };
      this.$http
        .get("/lotto/cancelall", { params })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch((error) => console.log(error));
    },

    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.upload-btn {
  border: 2px dashed #ccc;
  display: inline-block;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 150px;
  font-size: 18px;
  z-index: 0;
}

.upload-btn input[type="file"] {
  display: none;
}

.clear-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
}
</style>
