export default [
  // ---------------- Admin ----------------
  {
    header: 'หน้าหลัก.',
    resource: 'User',
  },
  {
    title: 'หน้าหลัก',
    route: 'dashboard-partner',
    icon: 'HomeIcon',
    resource: 'User',
  },

  // Buy number
  {
    header: 'ซื้อหวย.',
    resource: 'Member',
  },
  {
    title: 'เติมเงิน',
    route: 'deposit-now',
    icon: 'FilePlusIcon',
    resource: 'Member',
  },
  {
    title: 'ซื้อหวยให้เมมเบอร์',
    route: 'round-huay',
    icon: 'FilePlusIcon',
    resource: 'Member',
  },
  // {
  //   title: 'ซื้อหวยให้เมมเบอร์',
  //   route: 'round-huay',
  //   icon: 'FilePlusIcon',
  //   resource: 'Member',
  // },
  // {
  //   title: 'ซื้อหวยให้เมมเบอร์ใหม่',
  //   route: 'round-huay',
  //   icon: 'FilePlusIcon',
  //   resource: 'Member',
  // },
  {
    title: 'รายการโพยที่ถูกรางวัล',
    route: 'result-win',
    icon: 'FileTextIcon',
    resource: 'Member',
  },
  // {
  //   title: 'รายการโพยทั้งหมด',
  //   route: 'lotto-list-member',
  //   icon: 'FileTextIcon',
  //   resource: 'Member',
  // },
  {
    title: 'รายการโพยทั้งหมด',
    route: 'lotto-list-memberV2',
    icon: 'FileTextIcon',
    resource: 'Member',
  },
  {
    title: 'ประวัติการออกรางวัล',
    route: 'lotto-result',
    icon: 'FileTextIcon',
    resource: 'Member',
  },
  {
    title: 'รายงานแพ้/ชนะ',
    route: 'winlose-new',
    icon: 'FileTextIcon',
    resource: 'Member',
  },
  // {
  //   title: 'ซื้อหวยให้เมมเบอร์ 1',
  //   route: 'buy-now-v1',
  //   icon: 'FilePlusIcon',
  //   resource: 'User',
  // },

  // ออกหวย
  {
    header: 'รายงานทั้งหมด.',
    resource: 'User',
  },
  // จัดการหวยของ Agent
  // {
  //   title: 'จัดการหวยเอเยนต์',
  //   route: 'manage-lotto-type-agent',
  //   icon: 'FileTextIcon',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'รายงานการแทง',
  //   icon: 'ClipboardIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'ดูของ สมาชิก',
  //       route: 'bet-report-member',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'ดูของรวม / คาดคะเนได้เสีย',
  //       route: 'bet-report',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'รอผลเดิมพัน',
  //       route: 'waitting-result',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     // {
  //     //   title: 'เลขอั้น',
  //     //   route: 'payout-limit',
  //     //   icon: 'ChevronsRightIcon',
  //     //   resource: 'Agadmin',
  //     // },
  //     // {
  //     //   title: 'ตั้งค่าการรับของ',
  //     //   route: 'share',
  //     //   icon: 'ChevronsRightIcon',
  //     //   resource: 'Agadmin',
  //     // },
  //     // {
  //     //   title: 'ตั้งค่ารับของแยกตามชนิด',
  //     //   route: 'share-type',
  //     //   icon: 'ChevronsRightIcon',
  //     //   resource: 'Agadmin',
  //     // },
  //     {
  //       title: 'ยกเลิกโพย / รายการแทง',
  //       route: 'lotto-list',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //   ],
  // },

  {
    title: 'รายงานการแทง',
    icon: 'ClipboardIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'ดูของ สมาชิก',
        route: 'manage-bet-report-member',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'ดูของรวม / คาดคะเนได้เสีย',
        route: 'manage-bet-report',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'รอผลเดิมพัน',
        route: 'manage-waitting-result',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'ยกเลิกโพย / รายการแทง',
        route: 'manage-lotto-list',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
    ],
  },

  // {
  //   header: 'รายงานทั้งหมด',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายงานแพ้ชนะ',
  //   icon: 'FilePlusIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'ดูของทั้งหมด',
  //       route: 'setbet',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'แพ้-ชนะ สุทธิ',
  //       route: 'win-loss',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'User',
  //     },
  //     {
  //       title: 'รายการที่ถูกรางวัล',
  //       route: 'pay',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     // {
  //     //   title: 'ผลการออกราวสัล',
  //     //   route: 'game-results',
  //     //   icon: 'ChevronsRightIcon',
  //     //   resource: 'Agadmin',
  //     // },
  //   ],
  // },

  {
    title: 'รายงานแพ้ชนะ',
    icon: 'FilePlusIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'ดูของทั้งหมด',
        route: 'manage-set-bet',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'รายการที่ถูกรางวัล',
        route: 'manage-win',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'แพ้-ชนะ สุทธิ',
        route: 'manage-win-loss',
        icon: 'ChevronsRightIcon',
        resource: 'User',
      },
      {
        title: 'แพ้-ชนะ ในเครือ',
        route: 'manage-win-loss-subagent',
        icon: 'ChevronsRightIcon',
        resource: 'SubAgent',
      },
    ],
  },

  // {
  //   header: 'รายการฝาก-ถอน',
  //   resource: 'User',
  // },

  {
    title: 'รายการฝาก-ถอน',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'รายการฝาก',
        route: 'deposit',
        icon: 'ChevronsRightIcon',
        resource: 'UserLine',
      },
      {
        title: 'รายการถอน',
        route: 'withdraw',
        icon: 'ChevronsRightIcon',
        resource: 'UserLine',
      },
    ],
  },

  // {
  //   title: 'รายงานสรุป',
  //   icon: 'FileIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'ประวัติการตั้งอั้น',
  //       route: 'member-transaction',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'User',
  //     },
  //     {
  //       title: 'กราฟโพย/ช่วงเวลา',
  //       route: 'round-graph',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'User',
  //     },
  //   ],
  // },

  // สำหรับ Company
  {
    header: 'จัดการหวยและออกหวย.',
    resource: 'AgSeamless',
  },
  // Saemless
  {
    title: 'จัดการหวยและออกหวย',
    icon: 'PieChartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'จัดการหวย',
        route: 'manage-lotto-status',
        icon: 'FileTextIcon',
        resource: 'AgSeamless',
      },
      {
        title: 'อัตราการจ่ายเงิน',
        route: 'manage-rate',
        icon: 'EditIcon',
        resource: 'AgSeamless',
      },
      {
        title: 'เลขอั้น/ปิดรับ',
        route: 'limit-closebet',
        icon: 'ClockIcon',
        resource: 'AgSeamless',
      },
      {
        title: 'ลดหลั่น',
        route: 'manage-descending',
        icon: 'ClockIcon',
        resource: 'AgSeamless',
      },
      // {
      //   title: 'ตั้งค่าการรับของ',
      //   route: 'share',
      //   icon: 'SettingsIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'ตั้งค่ารับของแยกตามชนิด',
      //   route: 'share-type',
      //   icon: 'SettingsIcon',
      //   resource: 'Admin',
      // },
    ],
  },

  // {
  //   title: 'จัดการหวยและออกหวย',
  //   icon: 'PieChartIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'จัดการหวย',
  //       route: 'manage-lotto-type',
  //       icon: 'FileTextIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'อัตราการจ่ายเงิน',
  //       route: 'rate',
  //       icon: 'EditIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'เลขอั้น/ปิดรับ',
  //       route: 'limit-closebet',
  //       icon: 'ClockIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'รอบแทง',
  //       route: 'manage-time',
  //       icon: 'ClockIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'ออกรางวัล',
  //       route: 'lotto',
  //       icon: 'ShuffleIcon',
  //       resource: 'Agadmin',
  //     },
  //   ],
  // },

  {
    header: 'ระบบการจัดการ.',
    resource: 'UserLine',
  },
  {
    title: 'จัดการสมาชิก',
    icon: 'UserCheckIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'รายชื่อเอเยนต์',
        route: 'manage-admin-agent',
        icon: 'UsersIcon',
        resource: 'Agadmin',
      },
      {
        title: 'รายชื่อแอดมิน',
        route: 'admin-management',
        icon: 'UsersIcon',
        resource: 'AgMange',
      },
      {
        title: 'รายชื่อสมาชิก',
        route: 'member',
        icon: 'UsersIcon',
        resource: 'UserLine',
      },
      // {
      //   title: 'เพื่มเมมเบอร์',
      //   route: 'add-member',
      //   icon: 'UsersIcon',
      //   resource: 'Agadmin',
      // },
      // {
      //   title: 'เพิ่มเอเย่นต์',
      //   route: 'add-agent',
      //   icon: 'UsersIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'รายชื่อเอเย่นต์',
      //   route: 'member-list',
      //   icon: 'UsersIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'รายชื่อที่ระงับ',
      //   route: 'member-ban',
      //   icon: 'UsersIcon',
      //   resource: 'Agadmin',
      // },
      // {
      //   title: 'ตั้งค่าสมัครสมาชิก',
      //   route: 'member-setting',
      //   icon: 'UsersIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'ประวัติการใช้งาน',
      //   route: 'history-list',
      //   icon: 'UsersIcon',
      //   resource: 'Agadmin',
      // },

      // {
      //   title: 'Promotion',
      //   route: 'promotion',
      //   icon: 'GiftIcon',
      //   resource: 'Admin',
      // },
    ],
  },

  // {
  //   header: 'ระบบผู้ช่วย',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'จัดการผู้ช่วย',
  //   icon: 'UsersIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'เพิ่มผู้ช่วย',
  //       route: 'assistant-create',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'รายชื่อผู้ช่วย',
  //       route: 'assistant',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'สิทธิ์ผู้ช่วย',
  //       route: 'assistant-permission',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //     {
  //       title: 'รายงานผู้ช่วยที่ถูกระงับ',
  //       route: 'assistant-ban',
  //       icon: 'ChevronsRightIcon',
  //       resource: 'Agadmin',
  //     },
  //   ],
  // },

  {
    header: 'ตั้งค่าระบบ.',
    resource: 'AgLine',
  },
  {
    title: 'ตั้งค่าระบบ',
    icon: 'SlidersIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Setting',
        route: 'setting',
        icon: 'SettingsIcon',
        resource: 'AgLine',
      },
      {
        title: 'ระบบชวนเพื่อน',
        route: 'invite-friend',
        icon: 'UserPlusIcon',
        resource: 'AgLine',
      },
      // {
      //   header: 'Admin Management',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'Agent List',
      //   route: 'agent-list',
      //   icon: 'SettingsIcon',
      //   resource: 'Admin',
      // },

      // Setting
    ],
  },

  {
    header: 'จัดการหวยสำหรับบริษัท.',
    resource: 'Agadmin',
  },
  {
    title: 'กติกาการเล่น',
    route: 'announce',
    icon: 'MessageCircleIcon',
    resource: 'Agadmin',
  },
  {
    title: 'รายการยกเลิกโพย',
    route: 'cancel',
    icon: 'PowerIcon',
    resource: 'Agadmin',
  },
  {
    title: 'จัดการหวยสำหรับบริษัท',
    icon: 'FolderPlusIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'จัดการหวย',
        route: 'lotto-status',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'กลุ่มหวย',
        route: 'group',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'ประเภทหวย',
        route: 'type',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'อัตราการจ่าย',
        route: 'manage-master-rate',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'รอบแทง',
        route: 'manage-master-round',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'รอบแทง อัตโนมัติ',
        route: 'manage-master-round-auto',
        icon: 'SettingsIcon',
        resource: 'Agadmin',
      },
      {
        title: 'ออกรางวัล',
        route: 'manage-master-result',
        icon: 'ShuffleIcon',
        resource: 'Agadmin',
      },
      {
        title: 'แพ้-ชนะ สุทธิ',
        route: 'manage-master-win-loss',
        icon: 'ChevronsRightIcon',
        resource: 'Agadmin',
      },
      // {
      //   title: 'ลดหลั่น',
      //   route: 'manage-descending',
      //   icon: 'SettingsIcon',
      //   resource: 'Agadmin',
      // },
      // {
      //   title: 'Agent',
      //   route: 'agent',
      //   icon: 'SettingsIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'Bank',
      //   route: 'bank',
      //   icon: 'CreditCardIcon',
      //   resource: 'Admin',
      // },
      // {
      //   title: 'Channel',
      //   route: 'channel',
      //   icon: 'CreditCardIcon',
      //   resource: 'Admin',
      // },
    ],
  },

  // {
  //   header: 'ระบบออโต้',
  //   resource: 'Agadmin',
  // },
  {
    title: 'ระบบออโต้',
    icon: 'AirplayIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'SMS ทั้งหมด',
        route: 'sms-all',
        icon: 'MailIcon',
        resource: 'Agadmin',
      },
      {
        title: 'SMS ฝาก',
        route: 'sms-deposit',
        icon: 'FilePlusIcon',
        resource: 'Agadmin',
      },
      {
        title: 'SMS ถอน',
        route: 'sms-withdraw',
        icon: 'FileMinusIcon',
        resource: 'Agadmin',
      },
      {
        title: 'True Money Wallet',
        route: 'true-money',
        icon: 'FilePlusIcon',
        resource: 'Agadmin',
      },
      {
        title: 'SCB Statement',
        route: 'statement',
        icon: 'FilePlusIcon',
        resource: 'Agadmin',
      },
      {
        title: 'KBANK Statement',
        route: 'statement-kbank',
        icon: 'FilePlusIcon',
        resource: 'Agadmin',
      },
      {
        title: 'SMS OTP',
        route: 'sms-otp',
        icon: 'MailIcon',
        resource: 'Agadmin',
      },
    ],
  },

]
