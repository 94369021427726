<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>

        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('Today')"
                >
                  วันนี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('Yesterday')"
                >
                  เมื่อวาน
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('ThisWeek')"
                >
                  อาทิตย์นี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('LastWeek')"
                >
                  อาทิตย์ที่แล้ว
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('ThisMonth')"
                >
                  เดือนนี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('LastMonth')"
                >
                  เดือนที่แล้ว
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-group>
                  <flat-pickr
                    v-model="rangeDate"
                    placeholder="เลือกวันที่"
                    class="form-control"
                    :config="{
                      mode: 'range',
                      enableTime: true,
                      dateFormat: 'Y-m-d',
                      time_24hr: true,
                      defaultHour: 0,
                    }"
                    @on-close="RangDateCal()"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <b-form-input
                  id="basicInput"
                  v-model="username"
                  placeholder="username"
                />
              </div>
              <div class="col-md-4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="btn-icon"
                  block
                  @click="username = null"
                >
                  <feather-icon icon="DeleteIcon" />
                  Clear
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <b-form-group
              class="mr-1 mb-md-0"
            >

              <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="toggleAll"
              >
                ทั้งหมด
              </b-form-checkbox>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="options"
                value-field="name"
                text-field="name"
                name="flavour-1"
                class="demo-inline-spacing"
                @change="getwinloss()"
              />
            </b-form-group>
          </div>
        </div>

      </b-card-body>

      <div style="overflow-x:auto;">
        <table
          id="zero_config"
          class="table table-bordered table-sm table-striped table-hover"
        >
          <thead>
            <tr class="text-center">
              <th
                rowspan="2"
                class="align-middle"
              >
                #
              </th>
              <th
                rowspan="2"
                class="align-middle"
              >
                ยูสเซอร์เนม
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                ยอดทั้งหมด
              </th>
              <th
                colspan="3"
                class="align-middle bg-info"
              >
                สมาชิก
              </th>
              <th
                colspan="3"
                class="align-middle bg-warning"
              >
                {{ UserData.agent_username }}
              </th>
            </tr>
            <tr class="text-center">
              <th
                style=""
                class="bg-info"
              >
                ถูกรางวัล
              </th>
              <th
                style=""
                class="bg-info"
              >
                ส่วนลด
              </th>
              <th
                style=""
                class="bg-info"
              >
                รวม
              </th>
              <th
                style=""
                class="bg-warning"
              >
                ถูกรางวัล
              </th>
              <th
                style=""
                class="bg-warning"
              >
                ส่วนลด
              </th>
              <th
                style=""
                class="bg-warning"
              >
                รวม
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data,index) in listdata"
              :key="data"
              class="text-center font-weight-bolder"
            >
              <td>{{ index + 1 }}</td>
              <th scope="row">
                <router-link
                  :to="{ name: 'Deposit-Withdraw', params: { id: data.username }}"
                  style="color: blue"
                >
                  {{ data.username }}
                </router-link>
              </th>
              <td
                v-if="data.winloss < 0"
                style="color:red"
              >{{ Commas(data.winloss.toLocaleString()) }}</td>
              <td
                v-else
              >{{ Commas(data.winloss.toLocaleString()) }}</td>
              <td>{{ Commas(data.win.toLocaleString()) }}</td>
              <td>{{ Commas(data.discount.toLocaleString()) }}</td>
              <td
                v-if="(data.win + data.discount - data.winloss) < 0"
                style="color:red"
              >{{ Commas((data.win + data.discount - data.winloss).toLocaleString()) }}</td>
              <td v-else>
                {{ Commas((data.win + data.discount - data.winloss).toLocaleString()) }}
              </td>

              <td
                v-if="data.win_agent < 0"
                style="color:red"
              >{{ Commas(data.win_agent.toLocaleString()) }}</td>
              <td
                v-else
              >{{ Commas(data.win_agent.toLocaleString()) }}</td>

              <td>{{ Commas(data.dis_agent.toLocaleString()) }}</td>
              <td
                v-if="data.total_agent < 0"
                style="color:red"
              >{{ Commas(data.total_agent.toLocaleString()) }}</td>
              <td v-else>
                {{ Commas(data.total_agent.toLocaleString()) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="text-right font-weight-bold text-white">
              <td
                colspan="2"
                class="text-center"
              >
                ทั้งหมด
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.winloss), 0) < 0"
                     class="text-center font-weight-bolder"
                     style="color:red"
                   >
                     {{ Commas(listdata.reduce((total, item) => total + Number(item.winloss), 0).toFixed(2)) }}<span />
                   </span>
                <span
                  v-else
                  class="text-center font-weight-bolder"
                >
                  {{ Commas(listdata.reduce((total, item) => total + Number(item.winloss), 0).toFixed(2)) }}<span />
                </span>
              </td>
              <td> <span class=" Simple">
                {{ Commas(listdata.reduce((total, item) => total + Number(item.win), 0).toFixed(2)) }}
              </span>
              </td>
              <td> <span class=" Simple">
                     {{ Commas(listdata.reduce((total, item) => total + Number(item.discount), 0).toFixed(2)) }}
                     <span
                       style="text-decoration: underline; cursor:pointer;"
                       onclick="if (!window.__cfRLUnblockHandlers) return false; changeFull($(this));"
                     />
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + (item.win + item.discount - item.winloss), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ Commas(listdata.reduce((total, item) => total + (item.win + item.discount - item.winloss), 0).toFixed(2)) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ Commas(listdata.reduce((total, item) => total + (item.win + item.discount - item.winloss), 0).toFixed(2)) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.win_agent), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ Commas(listdata.reduce((total, item) => total + Number(item.win_agent), 0).toFixed(2)) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ Commas(listdata.reduce((total, item) => total + Number(item.win_agent), 0).toFixed(2)) }}
                </span>
              </td>
              <td> <span class=" Simple">
                     {{ Commas(listdata.reduce((total, item) => total + Number(item.dis_agent), 0).toFixed(2)) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.total_agent), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ Commas(listdata.reduce((total, item) => total + Number(item.total_agent), 0).toFixed(2)) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ Commas(listdata.reduce((total, item) => total + Number(item.total_agent), 0).toFixed(2)) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>

            </tr>
          </tfoot>
        </table>
      </div>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox, BFormCheckboxGroup, BInputGroupPrepend, BModal, BCard,
  // BTable, BAvatar,
  BFormGroup,
  // BFormSelect, BPagination,
  BInputGroup, BFormInput,
  // BInputGroupAppend,
  BButton,
  BCardBody, VBToggle,
  BOverlay,
  BIconController,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    flatPickr,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      show: true,
      rangeDate: null,
      days: null,
      twinloss: 0.00,
      twaiting: 0.00,
      tdiscount: 0.00,
      twitingdiscount: 0.00,
      selected: [],
      options: [],
      listdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'appid', label: 'appid' },
        { key: 'winloss', label: 'win/loss' },
        { key: 'discount', label: 'ส่วนลด' },
        { key: 'waiting', label: 'รอผล' },
        { key: 'witingdiscount', label: 'ส่วนลดรอผล' },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      allSelected: false,
      indeterminate: false,
      start: null,
      end: null,
      username: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  mounted() {
    // this.Get_userlist()
    this.GetData()
    // this.getwinloss()
  },
  methods: {
    getwinloss(days) {
      this.show = true
      if (days == "Today") {
        this.start = moment().startOf('day').format('YYYY-MM-DD')
        this.end = moment().startOf('day').format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else if (days == "Yesterday") {
        this.start = moment().startOf('day').subtract(1, "days").format('YYYY-MM-DD')
        this.end = moment().startOf('day').subtract(1, "days").format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else if (days == "ThisWeek") {
        this.start = moment().startOf("week").format('YYYY-MM-DD')
        this.end = moment().endOf("week").format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else if (days == "LastWeek") {
        this.start = moment().subtract(1, "weeks").startOf("week").format('YYYY-MM-DD')
        this.end = moment().subtract(1, "weeks").endOf("week").format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else if (days == "ThisMonth") {
        this.start = moment().startOf("month").format('YYYY-MM-DD')
        this.end = moment().endOf("month").format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else if (days == "LastMonth") {
        this.start = moment().subtract(1, "months").startOf("month").format('YYYY-MM-DD')
        this.end = moment().subtract(1, "months").endOf("month").format('YYYY-MM-DD')
        this.rangeDate = `${this.start} to ${this.end}`;
      } else {
        this.start = moment().startOf('day').format('YYYY-MM-DD')
        this.end = moment().startOf('day').format('YYYY-MM-DD')
      }
      // this.show = false
      const params = {
        selected: this.selected,
        days,
        start: this.start,
        end: this.end,
        username: this.username,
      }
      this.$http
        .get('/history/showall', { params })
        .then(response => {
          localStorage.setItem("SearchFilter", JSON.stringify(params));
          this.listdata = response.data
          this.show = false
        })
        .catch(error => {
          console.log(error)
          this.show = false
          this.listdata = []
          this.twinloss = 0.00
          this.twaiting = 0.00
          this.tdiscount = 0.00
          this.twitingdiscount = 0.00
        })
    },
    RangDateCal() {
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]

      this.start = start
      this.end = end
      if (this.start && this.end) {
        this.getwinloss(null)
      }
    },
    wluser(username) {
      const paramss = {
        username,
        days: this.days,
        selected: this.selected,
      }
      this.$router.push('/win-loss-user')
      localStorage.setItem('wlParams', JSON.stringify(paramss))
    },
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
      this.getwinloss()
    },
    async GetData() {
      await this.$http
        .get('lottotype')
        .then(async response => {
          this.show = false
          this.options = response.data.map(ele => ele.name)
          // eslint-disable-next-line no-restricted-syntax
          // for await (const da of response.data) {
          //   this.options.push(da.name)
          // }
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
thead,
tfoot {
  background-color: gold;
  color: rgb(0, 0, 0);
  text-align: center;
}

tbody {
  background-color: #f1e8e8;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  text-align: center;
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}
</style>
